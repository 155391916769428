import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useEmbedJourneyFlags } from 'src/dashboard/embed/journey-flags.hook';
import { EmbedPlayerEmailGating } from 'src/dashboard/embed/player-email-gating';
import JourneyInactive from 'src/player/JourneyInactive';
import JourneyDisabled from 'src/player/JourneyDisabled';
import { Nullable } from 'src/types/nullable.type';
import {
  getAliasGateViewedForJourney,
  getEmailWithAccessForJourney,
  setAliasGateViewedForJourney,
  storeAccessedEmailForJourney,
} from 'src/utils/journeyAccess';
import { apiSignNda, apiUnlockJourneyByToken } from 'src/utils/journeyApi';
import { getJourneySession, getJourneyTracker, getSessionReplayId } from 'src/utils/tracker';
import queryString from 'query-string';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { useUpdateEffect } from 'react-use';
import { useBatchEvents } from '../utils/batch-events.hook';
import { Journey } from 'src/common/interfaces/journey.interface';
import { trackSegmentEvent } from 'src/utils/segment';
import { useNeuePlayerUserEventsStore } from './user-events.store';
import { AliasCustomFieldValueType } from 'src/common/interfaces/journey/alias.interface';
import { useEventRecorder } from 'src/dashboard/rrweb/use-event-recorder';
import { UTMParameters } from 'src/utils/user/event.interface';

type Props = {
  journey: Nullable<Journey>;
  uuid: string;
  token: string;
  previewMode?: boolean;
  pdfPreview?: boolean;
  bypass?: Nullable<string>;
  trackingUUID: Nullable<string>;
  children: React.ReactNode;
  preFilledEmail?: Nullable<string>;
  preFilledName?: Nullable<string>;
  utmParameters?: UTMParameters;
};

export const NeuePlayerAccessControlAndTracking = ({
  uuid,
  token,
  previewMode = false,
  pdfPreview = false,
  bypass = null,
  trackingUUID,
  journey,
  children,
  preFilledEmail,
  preFilledName,
  utmParameters,
}: Props) => {
  const journeyHasBeenViewed = useRef(false);
  const [userEmail, setUserEmail] = useState<Nullable<string>>(
    preFilledEmail || getEmailWithAccessForJourney(uuid) || null
  );
  const [passwordIsVerified, setPasswordIsVerified] = useState<boolean>(false);

  const journeyTrackingUUID = useMemo(() => trackingUUID || getJourneyTracker(), [trackingUUID]);
  const journeySessionUUID = useMemo(getJourneySession, []);
  const sessionReplayUUID = useMemo(getSessionReplayId, []);

  useEventRecorder(sessionReplayUUID, journey, uuid);

  const customFieldValuesRef = useRef<AliasCustomFieldValueType[]>([]);

  useEffect(() => {
    if (preFilledName && preFilledEmail) {
      const nameField: AliasCustomFieldValueType = {
        field: 'name',
        label: 'Name',
        type: 'string',
        value: preFilledName
      };
      customFieldValuesRef.current = [nameField];
    }
  }, [preFilledName, preFilledEmail]);

  const [isGateAlreadyViewed, setIsGateAlreadyViewed] = useState<boolean>(
    getAliasGateViewedForJourney(uuid, journeySessionUUID)
  );

  const { setSuccessNotification } = useNotificationMessage();

  const userEventsFactoryStore = useNeuePlayerUserEventsStore();

  const {
    isJourneyInactive,
    isJourneyDraftOrPrivate,
    isJourneyPasswordProtected,
    isJourneyEmailGated,
    isEmailVerificationRequired,
    isNdaSignatureRequired,
    isEmailGateUnlocked,
    checkIfNdaSignatureRequired,
    customFields,
    onNdaSigned,
    unlockEmailGate,
    isJourneyWhitelistEmailGated,
  } = useEmbedJourneyFlags(journey, uuid, userEmail, passwordIsVerified, false, bypass);

  const emailRequired = isJourneyEmailGated || isJourneyWhitelistEmailGated;

  const shouldRenderJourneyGate = useMemo(
    () =>
      !!journey &&
      !isGateAlreadyViewed &&
      (emailRequired || isJourneyPasswordProtected || isNdaSignatureRequired) &&
      !isEmailGateUnlocked,
    [
      journey,
      emailRequired,
      isEmailGateUnlocked,
      isGateAlreadyViewed,
      isJourneyPasswordProtected,
      isNdaSignatureRequired,
    ]
  );

  useEffect(() => {
    userEventsFactoryStore.initialize({
      aliasUUID: uuid,
      sessionUUID: journeySessionUUID,
      trackingUUID: journeyTrackingUUID,
      sessionReplayUUID: sessionReplayUUID,
      email: userEmail,
      utmParameters: utmParameters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeySessionUUID, journeyTrackingUUID, userEmail, uuid, utmParameters]);

  useEffect(() => {
    if (journey && userEmail && !isEmailVerificationRequired) {
      checkIfNdaSignatureRequired(userEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, isEmailVerificationRequired]);

  const isJourneyTrackingActive = useMemo(() => {
    // if there is no joureny, then turn off tracking
    if (!journey) return false;

    // if preview mode is enabled, then turn off tracking
    // TODO: @anilsevim DO NOT remove this since the thumbnail job is using this
    if (previewMode) return false;

    // if preview mode is enabled, then turn off tracking
    // used by pdf generation
    if (pdfPreview) return false;

    return !isJourneyInactive && !isJourneyPasswordProtected && !isJourneyEmailGated;
  }, [journey, previewMode, pdfPreview, isJourneyEmailGated, isJourneyInactive, isJourneyPasswordProtected]);

  useBatchEvents({ uuid, journeySessionUUID, journeyTrackingUUID, sessionReplayUUID }, userEmail, isJourneyTrackingActive, utmParameters);

  const userEventsFactory = userEventsFactoryStore.instance;

  const trackJourneyView = () => {
    if (!isJourneyTrackingActive || journeyHasBeenViewed.current) {
      return;
    }

    const journeyViewEvent = userEventsFactory!.createJourneyViewEvent();
    const searchParams = queryString.parse(window.location.search);
    
    // Include UTM parameters in the journey view event
    journeyViewEvent.fire({
      ...searchParams,
      utm_parameters: utmParameters
    });
    
    journeyHasBeenViewed.current = true;
  };

  const onJourneyUnlocked = async (email?: string, alias_uuid: string = uuid) => {
    let response = null;

    storeAccessedEmailForJourney(alias_uuid, email || 'anonymous');
    setAliasGateViewedForJourney(alias_uuid, journeySessionUUID);
    setIsGateAlreadyViewed(true);
    setPasswordIsVerified(true);
    unlockEmailGate();

    await checkIfNdaSignatureRequired(email || '');

    if (email && journey && journey.creator?.email !== email) {
      const emailCaptureEvent = userEventsFactory!.createEmailCaptureEvent(email);
      customFieldValuesRef.current = customFieldValuesRef.current.map((customField) => ({
        ...customField,
        type: customField.field === 'phone_number' ? 'number' : 'string',
      }));
      response = await emailCaptureEvent.fire(customFieldValuesRef.current);
      trackJourneyView();
      setUserEmail(email);
      trackSegmentEvent('Email Gate Passed', {
        email: email,
        journey_alias_uuid: alias_uuid,
      });
    } else {
      trackJourneyView();
    }

    window.history.pushState({}, document.title, window.location.pathname);

    return response;
  };

  useUpdateEffect(() => {
    if (isJourneyTrackingActive) {
      trackJourneyView();
    }
  }, [isJourneyTrackingActive]);

  useUpdateEffect(() => {
    // if there is a token in the url, try to unlock the journey with it.
    if (token) {
      apiUnlockJourneyByToken(token)
        .then((res) => {
          const { email, alias_uuid } = res;
          return onJourneyUnlocked(email, alias_uuid);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [token]);

  const onJourneyUnlockedPendingNda = (email?: string) => {
    return checkIfNdaSignatureRequired(email || '')
      .then((isNdaRequired: boolean) => {
        if (!isNdaRequired && journey) {
          onJourneyUnlocked(email || userEmail || '', journey.alias.uuid);
        }
      })
      .catch(unlockEmailGate);
  };

  const onCustomFieldValuesSubmitted = (customFieldValues: AliasCustomFieldValueType[]) => {
    // set thid to local ref value
    customFieldValuesRef.current = customFieldValues;
  };

  // Add effect to auto-proceed with pre-filled email when verification is required
  useEffect(() => {
    if (
      journey && 
      preFilledEmail && 
      isEmailVerificationRequired && 
      !isGateAlreadyViewed && 
      !isEmailGateUnlocked
    ) {
  
        onJourneyUnlockedPendingNda(preFilledEmail);
      
    }
  }, [
    journey,
    preFilledEmail,
    isEmailVerificationRequired,
    isGateAlreadyViewed,
    isEmailGateUnlocked,
    onJourneyUnlockedPendingNda
  ]);

  if (journey) {
    if (isJourneyDraftOrPrivate) {
      return <JourneyDisabled journey={journey} />;
    }

    if (isJourneyInactive) {
      return <JourneyInactive journey={journey} />;
    }

    if (shouldRenderJourneyGate) {
      const onSignNda = (
        email: string,
        signature: string,
        customFields?: { fullName: string; companyName: string }
      ) => {
        if (journey) {
          apiSignNda(journey.alias.uuid, email, signature, customFields?.fullName, customFields?.companyName).then(
            () => {
              onNdaSigned();
              // add success notification
              setSuccessNotification('Signed and email sent to you.');
              return onJourneyUnlocked(userEmail || '');
            }
          );
        }
      };

      return (
        <EmbedPlayerEmailGating
          userEmail={userEmail}
          journey={journey}
          uuid={uuid}
          trackingUUID={journeyTrackingUUID}
          sessionUUID={journeySessionUUID}
          emailRequired={emailRequired}
          passwordRequired={isJourneyPasswordProtected}
          emailVerificationRequired={isEmailVerificationRequired}
          ndaSignatureRequired={isNdaSignatureRequired}
          customFields={customFields}
          onJourneyUnlocked={onJourneyUnlockedPendingNda}
          onNdaSigned={onSignNda}
          onEmailSubmitted={setUserEmail}
          onCustomFieldValuesSubmitted={onCustomFieldValuesSubmitted}
        />
      );
    }
  }

  return <>{children}</>;
};
