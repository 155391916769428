import React, { useEffect } from 'react';
import queryString from 'query-string';
import { H } from 'highlight.run';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { getAnonymousId } from './utils/segment';
import { EmbedPlayer } from 'src/dashboard/embed/player';
import { NeuePlayer } from 'src/editor/content-creation/neue-player';
import { NotificationMessage } from 'src/common/notification/message';
import { apiGetCurrentUser } from './utils/journeyApi';
import { useCurrentUser } from './store/user';
import { LocationChangeListener } from 'src/common/location-change-listener';
import AnalyticsHelper from 'src/common/helpers/analytics';
import { SentryHelper } from './common/sentry';
// import {record} from 'rrweb';

function EmbedApp() {
  const setCurrentUser = useCurrentUser((state) => state.setCurrentUser);
  const identifyAnonymousUser = () => {
    // Grab the uuid from segment if available
    const anonymousId = getAnonymousId();

    // Sentry identify
    SentryHelper.setUser('', anonymousId);

    // Highlight identify
    H.identify &&
      H.identify(anonymousId, {
        id: anonymousId,
      });
  };

  const queryParams = queryString.parse(window.location.search);
  const [bodyElement] = document.getElementsByTagName('body');
  const uuid = bodyElement.getAttribute('data-uuid');
  const neue = bodyElement.getAttribute('data-neue');
  const disableIndexing = bodyElement.getAttribute('data-disable-indexing') === '1';

  const trackingUUID = queryParams.tracking_uuid_override || null;
  const previewMode = queryParams.preview || false;
  const pdfPreview = !!queryParams.pdf_preview;
  const bypass = queryParams.bypass || null;
  
  // Extract UTM parameters
  const utmParameters = {
    utm_source: queryParams.utm_source || null,
    utm_medium: queryParams.utm_medium || null,
    utm_campaign: queryParams.utm_campaign || null,
    utm_term: queryParams.utm_term || null,
    utm_content: queryParams.utm_content || null
  };

  useEffect(() => {
    H.init(process.env.HIGHLIGHT_KEY_PLAYER, {
      environment: process.env.ENVIRONMENT,
    });

    apiGetCurrentUser().then(setCurrentUser).catch(identifyAnonymousUser);

    if (!previewMode) {
      AnalyticsHelper.trackPage();
    }
  }, []);

  // const sendEventsToServer = (session_id,eventsBatch,duration) => {
  //   const params = {
  //     events: eventsBatch,
  //     session_uuid: session_id,
  //     duration: duration
  //   }
  //   apiSendSessionEventsToServer(uuid,params)
  // };

  // useEffect(() => {
  //   let events = [];
  //   const session_id =  uuidv4();
  //   const startTime = Date.now(); 
  //   // Start recording
  //   const stopFn = record({
  //     emit(event) {
  //       events.push(event);

  //       if (events.length >= 50) {
  //         const endTime = Date.now(); 
  //         const duration = Math.floor((endTime - startTime) / 1000);
  //         sendEventsToServer(session_id, events.splice(0, events.length), duration);
  //       }
  //     },
  //   });

  //   return () => {
  //     stopFn();
  //   };
  // }, []);

  return (
    <Router>
      <LocationChangeListener />
      <NotificationMessage />
      <Switch>
        <Route
          path={`/p/:aliasSlug/:stepPath?`}
          render={(props) =>
            neue ? (
              <NeuePlayer
                disableIndexing={disableIndexing}
                token={queryParams.token}
                uuid={uuid}
                stepPath={props.match.params.stepPath}
                previewMode={queryParams.preview}
                aliasSlug={props.match.params.aliasSlug}
                pdfPreview={pdfPreview}
                bypass={bypass}
                commentIdToScroll={queryParams.comment || ''}
                suggestedCommenterUserUUID={queryParams.suggested_user_uuid || ''}
                trackingUUID={trackingUUID}
                utmParameters={utmParameters}
              />
            ) : (
              <EmbedPlayer
                disableIndexing={disableIndexing}
                uuid={uuid}
                trackingUUID={trackingUUID}
                startNodeId={queryParams.start || null}
                stepPath={props.match.params.stepPath}
                commentIdToScroll={queryParams.comment || ''}
                suggestedCommenterUserUUID={queryParams.suggested_user_uuid || ''}
                previewMode={queryParams.preview}
                galleryMode={queryParams.gallery}
                token={queryParams.token}
                utmParameters={utmParameters}
              />
            )
          }
        ></Route>
        <Route
          path={`/e/:aliasSlug/:stepPath?`}
          render={(props) => (
            <EmbedPlayer
              uuid={uuid}
              trackingUUID={trackingUUID}
              startNodeId={queryParams.start || null}
              aliasSlug={props.match.params.aliasSlug}
              stepPath={props.match.params.stepPath}
              commentIdToScroll={queryParams.comment || ''}
              suggestedCommenterUserUUID={queryParams.suggested_user_uuid || ''}
              previewMode={queryParams.preview}
              galleryMode={queryParams.gallery}
              token={queryParams.token}
              utmParameters={utmParameters}
            />
          )}
        ></Route>
        <Route path='*'>
          <EmbedPlayer
            uuid={uuid}
            trackingUUID={trackingUUID}
            startNodeId={queryParams.start || null}
            commentId={queryParams.comment || ''}
            suggestedCommenterUserUUID={queryParams.suggested_user_uuid || ''}
            previewMode={queryParams.preview}
            galleryMode={queryParams.gallery}
            token={queryParams.token}
            utmParameters={utmParameters}
          />
        </Route>
      </Switch>
    </Router>
  );
}

export default EmbedApp;
